<template>
  <section id="floating-alerts">
    <v-alert
      v-for="(alert, index) in alerts"
      :key="index"
      :type="alert.type"
      class="elevation-8"
      border="top"
      dismissible
    >
      <div v-if="Array.isArray(alert.message)">
        <div v-for="(message, i) in alert.message" :key="i">
          {{ $phraser.set(message) }}
        </div>
      </div>
      <div v-else>
        {{ $phraser.set(alert.message) }}
      </div>
    </v-alert>
  </section>
</template>

<script>
export default {
  name: 'FloatAlerts',
  data: () => ({
    killerInterval: null,
  }),

  computed: {
    alerts () {
      return this.$store.getters['alerts/all'] ?? []
    },
  },

  mounted () {
    clearInterval(this.killerInterval)
    this.killerInterval = null
    this.setKillerInterval()
  },

  methods: {
    setKillerInterval () {
      clearInterval(this.killerInterval)
      this.killerInterval = null
      this.killerInterval = setInterval(() => {
        for (const alert of this.alerts) {
          if (+new Date() >= alert.id + alert.displaytime * 1000) {
            this.$store.dispatch('alerts/deleteAlert', alert.id)
          }
        }
      }, 1000)
    },
  },
}
</script>

<style scoped lang="sass">
#floating-alerts
  position: fixed
  z-index: 999
  top: 80px
  right: 20px
  .v-alert
    max-width: calc(100vw - 40px)
    width: 480px
    div
      font-size: 12pt

.v-application--is-rtl #floating-alerts
  right: auto
  left: 20px
</style>
